.form {
    padding: 10px 0 0 10px;
}

.label {
    margin-bottom: 10px;
    font-size: 16px;
}

.label:not(:first-child) {
    margin-top: 30px;
}

#small-indicator, #medium-indicator, #large-indicator {
    vertical-align: middle;
    margin-right: 10px;
}

#button,
#button .dx-button-content {
    font-size: 0.8rem;
  border-radius: 10rem;
  padding: 0.4rem 1rem;
    /* color: #fff; */
    /* background-color: #007bff;
    border-color: #007bff; */
}

#button .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}